import React from 'react';
import BlogArticle from 'components/blog/blogArticle';
import { Post } from 'components/blog/content/styled';
import { authorMarcel as author } from 'data/authors';
import cover from 'img/blog/covers/segment-notifications.png';
import img from 'img/help/segments-notifications/notifications1.png';
import img1 from 'img/help/segments-notifications/notifications2.png';
import img2 from 'img/help/segments-notifications/notifications3.png';

const Content = () => {
  return (
    <Post>
      <p>We’ve added segments notifications to LiveSession!</p>
      <p>
        From now, you can get notified about the changes in a chosen segments on email.
        Notifications can be sent on a daily or weekly basis.
      </p>
      <p>To get started, simply click the “Add to summary” button while the segment is selected.</p>
      <img src={img} alt="Segments notifications - preview" title="Add to summary" />
      <p>
        Next day, you&apos;ll receive a summarized list of the segments you’re following with the
        number of sessions from the past day or week (depending on your settings).
      </p>
      <img src={img1} alt="Segments notifications email - preview" title="Segments summary email" />
      <p>You can change Segments summary settings: “Settings &gt; Notifications”:</p>
      <img src={img2} alt="Notifications in settings" title="Segments summary settings" />
      <p>Also, you can add or remove segments that you want to be included in the summary.</p>
    </Post>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Segments summary email',
  url: '/blog/segments-summary-email/',
  description: `Introducing segments notifications! Get a daily or weekly email summary of segments that you follow.`,
  author,
  img: cover,
  imgSocial: cover,
  date: '2021-01-18',
  category: '',
  group: 'updates',
  timeToRead: 1,
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    timeToRead={frontmatter.timeToRead}
  />
);
